.formatBox {
  display: flex;
  width: 100%;
  font-family: 'Roboto';
  background-color: #fff;
  .content {
    overflow: hidden;
    background: linear-gradient(180deg, #fafafa 23.44%, #eee 100%);
    border-radius: 8px;
    .formatLabel {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;
      width: 144px;
      padding: 0 12px;
      color: #333;
      font-size: 14px;
    }
    .formatValues {
      display: flex;
      align-items: center;
      height: 48px;
      overflow: hidden;
      background-color: #f7f7f7;
      border-radius: 8px;
      .formatValue {
        position: relative;
        padding: 16px 20px;
        color: #333;
        font-size: 16px;
        white-space: nowrap;
        cursor: pointer;
        &:not(:last-child) {
          &::before {
            position: absolute;
            top: 10px;
            right: 0;
            width: 1px;
            height: 32px;
            background-color: #c1c1c1;
            content: '';
          }
        }
      }
      .active {
        color: #ff4311;
        background-color: #ffede8;
        &::before {
          display: none;
        }
        &::after {
          position: absolute;
          top: 0;
          left: -1px;
          width: 1px;
          height: 48px;
          background-color: #ffede8;
          content: '';
        }
      }
    }
  }
  .mContent {
    display: none;
  }
  .formatOptions {
    position: relative;
    display: flex;
    align-items: center;
    padding: 32px 0;
    background-color: #fff;
    .iconTips {
      width: 20px;
      height: 20px;
      margin-right: 6px;
      cursor: pointer;
    }
  }
}

.tips {
  max-width: 500px;
  padding: 10px;
}

.popoverItem {
  position: relative;
  :global(.am-popover-item-container) {
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .formatBox {
    display: flex;
    margin-bottom: 23px;
    .step {
      display: none;
    }
    .content {
      display: none;
    }
    .mContent {
      display: block;
      box-sizing: border-box;
      width: 100vw;
      // margin: 0 4%;
      border-bottom: 1px solid #d8d8d8;
      :global {
        .am-list-item {
          min-height: 40px;
          padding-left: 0;
          .am-list-line {
            padding-right: 5px;
            .am-list-content {
              color: #000;
              font-weight: 500;
              font-size: 16px;
              font-family: Roboto;
            }
            .am-list-extra {
              flex-basis: 50%;
              color: #000;
              font-size: 16px;
              font-family: Roboto;
            }
            .am-list-arrow-horizontal {
              width: 7px;
              height: 10px;
            }
          }
        }
      }
    }
  }
}
